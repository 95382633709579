<ion-content [fullscreen]="true">
  <main>
    <article class="picture">
      <div class="borderr">
        <div class="avatar">
          <img
            [src]="getAvatar()"
            alt="Avatar"
            onerror="this.onerror=null; this.src='https://ionicframework.com/docs/img/demos/avatar.svg';"
          />
        </div>
      </div>
      <h1 class="title">Llamada entrante</h1>
      <ng-container *ngIf="data['members']">
        <h2 *ngFor="let member of data.members" class="name">
          {{getNameOfCaller(member)}}
        </h2>
      </ng-container>
    </article>
    <div class="message">
      <div class="icons">
        <div class="msg-later" (click)="endCallAndGoToChat(data['members'][0])">
          <ion-icon src="assets/icons/message-circle-off.svg" />
          <small>‎ </small>
          <p>Cortar, ir a chat</p>
        </div>
        <div
          class="msg-later"
          (click)="endCallAndSendMessage(data['members'][0])"
        >
          <ion-icon name="chatbubble-ellipses-outline" />
          <small>Responder por chat</small>
          <p>No puedo ahora, te llamo luego</p>
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="btns-ctn">
        <button class="buttonn cancel" (click)="rejectCall()">
          <ion-icon class="big" src="assets/icons/call-end.svg" />
        </button>
        <button class="buttonn call" (click)="acceptCall()">
          <ion-icon class="big" src="assets/icons/call.svg" />
        </button>
      </div>
    </div>
  </main>
</ion-content>
