import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { constants } from '../constants/constants';
import { ControlService } from './control.service';
import { getErrorMessage } from './app.service';

export function loggingInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const authService = inject(AuthService);
  const router = inject(Router);
  const controlService = inject(ControlService);

  const authData = authService.authData.getValue();
  const token = authData ? authData.token : null;

  const newReq = token
    ? req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
        url: `${environment.apiUrl}${req.url}`,
      })
    : req.clone({ url: `${environment.apiUrl}${req.url}` });

  return next(newReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 || error.status === 403) {
        controlService.close_modal();
        controlService.show_toast(getErrorMessage(error));
        authService.logout();
        router.navigate([constants.routes.auth.default], {
          replaceUrl: true,
        });
      }
      return throwError(() => error);
    })
  );
}
