<form [formGroup]="form" id="footer">
  <div
    class="ctn"
    [ngClass]="{
      focus: inpusIsFocus,
      noFocus: !inpusIsFocus
    }"
  >
    <ion-item lines="none" class="text-box-item ion-no-padding">
      <ion-input
        (click)="onInputClick()"
        (ionBlur)="onInputBlur()"
        placeholder="Ingresa tu mensaje"
        [autofocus]="true"
        auto-grow="true"
        (input)="onInputChange()"
        formControlName="prompt"
      >
      </ion-input>
    </ion-item>
    <div class="sender-btn">
      <ion-buttons slot="end">
        <button [disabled]="isEmpty" (click)="sendMessage()">
          <ion-icon name="send"></ion-icon>
        </button>
      </ion-buttons>
    </div>
  </div>
</form>
