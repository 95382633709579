<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon name="chevron-back-outline" slot="start"></ion-icon>
      </ion-button>
      <ion-avatar class="avatar">
        <img
          [src]="getAvatar()"
          [alt]="'Avatar'"
          onerror="this.onerror=null; this.src='https://ionicframework.com/docs/img/demos/avatar.svg';"
        />
      </ion-avatar>
    </ion-buttons>
    <ion-buttons>
      <ion-title>
        <div class="title-container">
          <p class="title-text">{{to.username}}</p>
          <p class="subtitle-text">{{to.phoneNumber}}</p>
        </div>
      </ion-title>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="callUser()">
        <ion-icon name="videocam-outline" />
      </ion-button>
      <ion-button [disabled]="true" *ngIf="false">
        <ion-icon name="ellipsis-vertical-outline" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="wrapper">
    <div class="chat-wrapper" #chatWrapper>
      <div *ngIf="getMessages().length > 0; else noMessages">
        <app-chat-messages
          [messages]="messages"
          [userId]="user.id"
        ></app-chat-messages>
      </div>
      <ng-template #noMessages>
        <ion-item-divider sticky class="date">
          Los mensajes y llamadas son encriptados end-to-end. Nadie fuera de este chat, ni siquiera Qubit, puede leerlos o escucharlos.
        </ion-item-divider>
      </ng-template>
      <div class="scroll-btn-ctn">
        <button #scrollButton class="none">
          <ion-icon name="arrow-down-outline"></ion-icon>
        </button>
      </div>
    </div>
    <app-chat-form
      (sendMessageEvent)="onSendMessage($event)"
      [userId]="user.id"
      [automaticPersonalizedMessage]="automaticPersonalizedMessage"
    />
  </div>
</ion-content>
