import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { constants as c, constants } from '../constants/constants';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket!: Socket;
  isConnected = new BehaviorSubject<boolean>(false);
  isConnecting = new BehaviorSubject<boolean>(false);

  constructor(private readonly storageService: StorageService) {}

  async load() {
    const jsonAuthData =
      (await this.storageService.get(constants.storage.authData)) ?? '';
    if (!jsonAuthData) return;

    const authData = JSON.parse(jsonAuthData);
    this.connect(authData?.token);
  }

  listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data: any) => {
        console.log(eventName, data);
        subscriber.next(data);
      });
    });
  }

  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  connect(token: string) {
    if (!this.isConnecting.value) {
      this.isConnecting.next(true);
      if (this.isConnected.value) {
        this.isConnecting.next(false);
        return;
      }

      this.socket = new Socket({
        url: environment.webSocketUrl,
        options: {
          transportOptions: {
            polling: {
              extraHeaders: {
                Authorization: token,
              },
            },
          },
        },
      });
      console.log('new socket');
      return new Promise<void>((res) => {
        this.socket.on('connection-success', () => {
          this.isConnected.next(true);
          this.isConnecting.next(false);
          res();
        });

        this.socket.on('connection-error', () => {
          this.isConnected.next(false);
          this.isConnecting.next(false);
          res();
        });
      });
    } else {
      return new Promise<void>((res) => {
        this.isConnecting.subscribe((val) => {
          if (!val) res();
        });
      });
    }
  }

  disconnect() {
    if (this.socket) this.socket.disconnect();
  }
}
