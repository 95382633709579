import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, map } from 'rxjs';
import { constants as c, constants } from '../constants/constants';
import { AuthData } from '../shared/models/auth';
import { StorageService } from './storage.service';
import {
  LoginRequestBody,
  RegisterRequestBody,
} from '../shared/models/request';
import { AppService } from './app.service';
import { User } from '../shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authData = new BehaviorSubject<AuthData | null>(null);

  constructor(
    private appService: AppService,
    private storageService: StorageService,
    private httpClient: HttpClient
  ) {}

  private async updateLoginData(authData: AuthData): Promise<void> {
    this.storageService.set({
      key: c.storage.authData,
      value: JSON.stringify(authData),
    });
    this.authData.next(authData);
  }

  async login(credentials: LoginRequestBody): Promise<AuthData> {
    const url = c.endpoints.auth.login;
    return lastValueFrom(
      this.httpClient.post<AuthData>(url, credentials).pipe(
        map((data: AuthData) => {
          this.updateLoginData(data);
          return data;
        }),
        catchError((error: any) =>
          this.appService.handleBackendError('login', error)
        )
      )
    );
  }

  register(credentials: RegisterRequestBody): Promise<AuthData> {
    const url = c.endpoints.auth.register;
    return lastValueFrom(
      this.httpClient.post<AuthData>(url, credentials).pipe(
        map((data: AuthData) => {
          this.updateLoginData(data);
          return data;
        }),
        catchError((error: any) =>
          this.appService.handleBackendError('register', error)
        )
      )
    );
  }

  async loadAuthData(): Promise<void> {
    const storageAuthData = await this.storageService.get(c.storage.authData);
    if (storageAuthData) {
      this.authData.next(JSON.parse(storageAuthData));
    }
  }

  async refreshAuthUser({ token }: AuthData): Promise<any> {
    const url = c.endpoints.auth.me;
    return lastValueFrom(
      this.httpClient.get(url).pipe(
        map(async (data) => {
          const user = data as User;
          await this.updateLoginData({ token, user });
          return user;
        }),
        catchError((error) =>
          this.appService.handleBackendError('refreshAuthUser', error)
        )
      )
    );
  }

  async resetOSId() {
    await lastValueFrom(
      this.httpClient.post(c.endpoints.auth.logout, undefined)
    );
  }

  async logout() {
    this.storageService.delete([constants.storage.authData]);
    this.authData.next(null);
  }
}
