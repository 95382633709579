import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { constants } from '../constants/constants';

export const AutoLoginGuard = async () => {
  const authenticationService = inject(AuthService);
  const router = inject(Router);

  await authenticationService.loadAuthData();
  const authData = authenticationService.authData.getValue();

  if (authData) {
    return true;
  } else {
    router.navigateByUrl(constants.routes.auth.default, { replaceUrl: true });
    return false;
  }
};
