import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { isSameDay, format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Message } from 'src/app/shared/models/chat.model';
import { ChatsService } from 'src/app/services/chats.service';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessagesComponent implements OnInit, OnDestroy {
  @Input() messages!: BehaviorSubject<any>;
  @Input() userId = '';
  parsedMessages: Record<string, Message[]> = {};
  messagesSubscription!: Subscription;

  public Object = Object;

  constructor(
    private readonly chatService: ChatsService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.messagesSubscription = this.messages.subscribe(
      (messages: Message[]) => {
        console.log('ngOnInit - Chat messages', messages);
        this.parsedMessages = this.chatService.parseMessagesByDate(messages);
        this.ref.detectChanges();
      }
    );
  }

  parseDate(dateString: string): string {
    const date = parseISO(dateString);
    const today = new Date();
    const dateFormat = 'dd/MM/yyyy';

    const locale = {
      locale: es,
    };

    if (isSameDay(date, today)) {
      return 'Hoy';
    } else if (
      date >
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
    ) {
      return format(date, 'EEEE', locale);
    } else {
      return format(date, dateFormat, locale);
    }
  }

  getHour(date: string | Date): string {
    return format(new Date(date), 'HH:mm');
  }

  isTodayLastMessage(i: number, date: string) {
    const today = format(new Date(), 'yyyy-MM-dd');
    return i + 1 === this.parsedMessages[date].length && today === date;
  }

  ngOnDestroy(): void {
    this.messagesSubscription.unsubscribe();
  }
}
