import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { position } from '../shared/enums/definitions.enum';
import { messageTime } from '../constants/configs';
import { IModal } from '../shared/models/controls.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControlService {
  modalIsActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    private toastCtrl: ToastController,
    private modalController: ModalController,
    private loadingCtrl: LoadingController
  ) {}

  async show_loader(message?: string, duration: number = 10000) {
    const loading = await this.loadingCtrl.create({
      message,
      duration,
    });

    return await loading.present();
  }

  async hide_loader() {
    this.loadingCtrl.dismiss().catch(() => {});
  }

  //** ------------------------------------------------------------> TOAST */
  async show_toast(
    message?: string,
    duration?: number,
    color?:
      | 'danger'
      | 'dark'
      | 'light'
      | 'primary'
      | 'secondary'
      | 'success'
      | 'tertiary'
      | 'warning',
    position?: position.top | position.bottom | position.middle
  ) {
    const toast = await this.toastCtrl.create({
      message,
      duration: duration ?? messageTime,
      color: color ?? undefined,
      position: position ?? undefined,
    });

    toast.present();
    return toast;
  }

  //** ------------------------------------------------------------> MODAL */
  async show_modal(
    config: IModal,
    functionToExecuteOnDismiss?: () => any
  ){
    const modal = await this.modalController.create({
      component: config.component,
      componentProps: config.componentProps,
      cssClass: config.cssClass,
      backdropDismiss: config.backdropDismiss,
    });

    await modal.present();
    this.modalIsActive.next(true);

    const { data } = await modal.onDidDismiss();
    if (data) {
      return data;
    }

    if (config.returnData) {
      const data = await modal.onDidDismiss();
      return data;
    }
  }

  close_modal(data?: any) {
    this.modalController.dismiss(data).catch((error) => console.log(error));
    this.modalIsActive.next(false);
  }
}
