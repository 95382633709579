import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.scss'],
})
export class ChatFormComponent {
  @Output() sendMessageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() userId = '';
  @Input() automaticPersonalizedMessage!: string;
  form = new FormGroup({
    prompt: new FormControl(''),
  });

  isEmpty = true;
  inpusIsFocus = false;

  ngOnInit() {
    if (this.automaticPersonalizedMessage) {
      this.form.controls.prompt.setValue(this.automaticPersonalizedMessage);
      this.isEmpty = false;
    }
  }

  onInputClick() {
    this.inpusIsFocus = true;
  }

  onInputBlur() {
    this.inpusIsFocus = false;
  }

  onInputChange() {
    this.isEmpty = this.form.value.prompt?.trim() === '';
  }

  async sendMessage() {
    const prompt = this.form.value.prompt;
    const message = {
      senderId: this.userId,
      createdAt: new Date(),
      content: prompt!,
    };
    console.log("messageToSend", message);

    this.form.reset();
    this.isEmpty = true;
    this.sendMessageEvent.emit(message);
  }
}
