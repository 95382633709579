import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { message } from '../constants/configs';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor() {}

  handleBackendError(operation: string, error: any) {
    const message = getErrorMessage(error);
    console.log(`${operation} failed: ${message}`);

    return throwError(() => new Error(message));
  }
}

export const getErrorMessage = (error: HttpErrorResponse) => {
  let messagg = '';
  const msg = error.error.message;
  if (typeof msg === 'string') messagg = error.error.message;
  else if (typeof msg === 'object') messagg = error.error?.message?.[0];

  return messagg ?? `${message.error.default}`;
};
