import { Injectable } from '@angular/core';
import { Chat } from '../shared/models/chat.model';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  async generateKey(payload: {
    SEED: string;
    SESSION: string;
    KEYS: number;
    BITS: number;
    start?: number;
  }) {
    return fetch(
      'https://soothing-enjoyment-development.up.railway.app/api/crypto',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (res) {
        return {
          keys: res.keys as number[],
          end: res.end,
        };
      });
  }

  vernam(data: Uint8Array, encryptionKey: number[]): Uint8Array {
    return data.map((x, i) => x ^ encryptionKey[Math.ceil(i / 4)]);
  }

  async decryptChat(
    chat: Chat,
    from: string,
    to: string,
    prev?: Chat
  ): Promise<Chat> {
    if (prev) return this.decryptWithPrev(chat, from, to, prev);
    return this.decryptWithoutPrev(chat, from, to);
  }

  async decryptWithPrev(chat: Chat, from: string, to: string, prev: Chat) {
    let messages = chat.Message ?? [];
    const keysInfo: any = {};
    keysInfo[from] = {
      i: 0,
      length: 0,
      keys: [],
      end: 0,
    };
    keysInfo[to] = {
      i: 0,
      length: 0,
      keys: [],
      end: 0,
    };
    if (chat.Message?.length) {
      messages.forEach((x) => {
        keysInfo[x.senderId!] = {
          ...keysInfo[x.senderId!],
          length: keysInfo[x.senderId!].length + x.content.length,
        };
      });

      for (let i = 0; i < Object.keys(keysInfo).length; i++) {
        const index = Object.keys(keysInfo)[i];
        if (keysInfo[index].length > prev.keysInfo[index].length) {
          const resp = await this.generateKey({
            SEED: chat.id,
            SESSION: index,
            KEYS: keysInfo[index].length - prev.keysInfo[index].length,
            BITS: 8,
            start: prev.keysInfo[index].end,
          });
          keysInfo[index] = {
            i: 0,
            length: keysInfo[index].length - prev.keysInfo[index].length,
            keys: prev.keysInfo[index].keys.concat(resp.keys),
            end: resp.end,
          };
        } else {
          keysInfo[index] = { ...prev.keysInfo[index], i: 0 };
        }
      }

      messages = messages.map((x) => {
        const resp = {
          ...x,
          content: x.content
            .split(' ')
            .map((y, i) =>
              String.fromCharCode(
                +y ^ keysInfo[x.senderId!].keys[keysInfo[x.senderId!].i + i]
              )
            )
            .join(''),
        };
        keysInfo[x.senderId!].i += x.content.length;
        return resp;
      });
    }
    return { ...chat, Message: messages, keysInfo };
  }

  async decryptWithoutPrev(chat: Chat, from: string, to: string) {
    let messages = chat.Message ?? [];
    const keysInfo: any = {};
    keysInfo[from] = {
      i: 0,
      length: 5000,
      keys: [],
      end: 0,
    };
    keysInfo[to] = {
      i: 0,
      length: 5000,
      keys: [],
      end: 0,
    };
    messages.forEach((x) => {
      keysInfo[x.senderId!] = {
        ...keysInfo[x.senderId!],
        length: keysInfo[x.senderId!].length + x.content.length,
      };
    });

    for (let i = 0; i < Object.keys(keysInfo).length; i++) {
      const index = Object.keys(keysInfo)[i];
      const resp = await this.generateKey({
        SEED: chat.id,
        SESSION: index,
        KEYS: keysInfo[index].length,
        BITS: 8,
        start: keysInfo[index].end,
      });
      keysInfo[index] = { ...keysInfo[index], ...resp };
    }

    messages = messages.map((x) => {
      const resp = {
        ...x,
        content: x.content
          .split(' ')
          .map((y, i) =>
            String.fromCharCode(
              +y ^ keysInfo[x.senderId!].keys[keysInfo[x.senderId!].i + i]
            )
          )
          .join(''),
      };
      keysInfo[x.senderId!].i += x.content.length;
      return resp;
    });
    return { ...chat, Message: messages, keysInfo };
  }
}
