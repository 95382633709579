import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginGuard } from './guards/auto-login.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainPageModule),
    canActivate: [AutoLoginGuard],
  },
  {
    path: 'meeting',
    loadChildren: () =>
      import('./pages/meeting/meeting.module').then((m) => m.MeetingPageModule),
    canActivate: [AutoLoginGuard],
  },
  {
    path: 'incoming-call',
    loadChildren: () =>
      import('./pages/incoming-call/incoming-call.module').then(
        (m) => m.IncomingCallPageModule
      ),
    canActivate: [AutoLoginGuard],
  },
  { path: '**', redirectTo: 'main/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
