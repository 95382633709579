import { Component, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { WebSocketService } from './services/websocket.service';
import { IModal } from './shared/models/controls.model';
import { IncomingCallPage } from './pages/incoming-call/incoming-call.page';
import { ControlService } from './services/control.service';
import { SocketReceiveCall } from './shared/models/socket.model';
import { ContactsService } from './services/contacts.service';
import { isMobile } from './services/utils.service';
import { OnesignalNotificationService } from './services/onesignal-notification.service';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { constants } from './constants/constants';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  reciveCallSubscription?: Subscription;

  constructor(
    private readonly oneSignalNotificationService: OnesignalNotificationService,
    private readonly webSocketService: WebSocketService,
    private readonly controlService: ControlService,
    private readonly contactsService: ContactsService,
    private readonly platform: Platform,
    private readonly router: Router,
    private readonly alertController: AlertController
  ) {
    this.initializeApp();
  }

  initializeApp(): void {
    this.platform.ready().then(async () => {
      if (this.platform.is('mobile')) {
        this.oneSignalNotificationService.bindNotifications();
      }
    });

    this.platform.backButton.subscribeWithPriority(999, async () => {
      if (this.controlService.modalIsActive.getValue()) {
        this.controlService.close_modal();
      } else {
        switch (this.router.url) {
          case '/auth':
            App.exitApp();
            break;
          case '/main/home':
            App.exitApp();
            break;
          default:
            if (this.router.url.includes('meeting')) {
              const alert = await this.alertController.create({
                header: '¿Desea terminar la llamada?',
                buttons: [
                  {
                    text: 'No',
                    role: 'cancel',
                  },
                  {
                    text: 'Si',
                    role: 'confirm',
                    handler: () => {
                      this.webSocketService.emit('hangup', {});
                    },
                  },
                ],
              });

              await alert.present();
            }
        }
      }
    });
  }
  async ngOnInit(): Promise<void> {
    await this.webSocketService.load();
    this.webSocketService.isConnected.subscribe((isConnected) => {
      if (isConnected) {
        if (this.reciveCallSubscription) {
          this.reciveCallSubscription.unsubscribe();
        }
        this.reciveCallSubscription = this.webSocketService
          .listen('recive-call')
          .subscribe(async (d) => {
            this.controlService.close_modal();
            const data = d as SocketReceiveCall;

            let loadedContacts =
              this.contactsService.contactsListFlat$.getValue();
            if (!isMobile()) {
              loadedContacts = [
                { display: 'leandro', phoneNumber: '+54 11 3580 3252' },
                { display: 'brandon', phoneNumber: '+54 11 4403 4356' },
              ];
            }
            const contact = loadedContacts.find(
              (c) => c.phoneNumber === data.members[0].phoneNumber
            );

            data.members[0].username = contact ? contact.display : '';

            const modal: IModal = {
              component: IncomingCallPage,
              componentProps: { data },
            };

            this.controlService.show_modal(modal);
          });
      }
    });
  }
}
