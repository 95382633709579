import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, timeout } from 'rxjs';
import { format } from 'date-fns';
import { NavigationExtras, Router } from '@angular/router';
import { ControlService } from 'src/app/services/control.service';
import { Chat, Message } from 'src/app/shared/models/chat.model';
import { WebSocketService } from 'src/app/services/websocket.service';
import { constants } from 'src/app/constants/constants';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { ChatsService } from 'src/app/services/chats.service';
import { CryptoService } from 'src/app/services/crypto.service';

@Component({
  selector: 'app-direct-chat',
  templateUrl: './direct-chat.page.html',
  styleUrls: ['./direct-chat.page.scss'],
})
export class DirectChatPage implements OnInit {
  @ViewChild('chatWrapper', { static: true }) chatWrapperRef!: ElementRef;
  @Input() chat: Chat | undefined;
  @Input() to!: User;
  @Input() automaticPersonalizedMessage!: string;

  private messageSubscription?: Subscription;

  public messagesByDate: Record<string, Message[]> = {};
  public user!: User;
  public messages = new BehaviorSubject<Message[]>([]);
  isBlocked = true;

  constructor(
    private authService: AuthService,
    private chatsService: ChatsService,
    private webSocketService: WebSocketService,
    private controlService: ControlService,
    private router: Router,
    private cryptoService: CryptoService
  ) {}

  async ngOnInit() {
    this.authService.authData.subscribe((auth) => {
      if (!auth) return;
      this.user = auth.user;
    });
  }

  async ionViewWillEnter() {
    if (!this.chat) {
      this.chat = await this.chatsService.forceGetChat(this.to.id);
    }

    this.messages.next(this.chat.Message ?? []);

    this.setupWebSocketListeners();
    this.syncOtherPersonChat();
  }

  ionViewDidEnter() {
    if (!this.chat && !this.to) {
      this.closeModal();
      return;
    }
    this.scrollToBottom();
    this.updateUnreadMessagesStatus();
  }

  async setupWebSocketListeners() {
    await this.webSocketService.load();

    if (this.chat) {
      this.messageSubscription = this.webSocketService
        .listen(`receive-message/${this.chat.id}`)
        .subscribe(async (message) => {
          await this.handleReceiveMessage(message as Message);
        });
      this.webSocketService
        .listen(`sync-chat/${this.chat.id}`)
        .subscribe(() => this.syncChat());
    }
  }

  async handleReceiveMessage(message: Message) {
    this.chat = await this.chatsService.getChat(this.to.id, this.chat);
    this.messages.next(this.chat.Message!);

    if (
      message.senderId !== this.user.id &&
      this.controlService.modalIsActive.getValue()
    ) {
      this.webSocketService.emit('receive-message', { message });
      this.webSocketService.emit('sync-chat', {
        otherPersonId: message.senderId,
        chatId: message.chatId,
      });
    }
    setTimeout(() => {
      this.scrollToBottom();
    }, 10);
  }

  private syncChat() {
    if (!this.chat) return;
    this.messages.next(
      this.messages.value.map((msg) => ({ ...msg, readedAt: 'true' }))
    );
  }
  private syncOtherPersonChat() {
    if (!this.chat) return;
    const haveUnreadMsg =
      !this.chat.Message?.at(-1)?.readedAt &&
      this.chat.Message?.at(-1)?.senderId !== this.user.id;

    if (haveUnreadMsg) {
      this.webSocketService.emit('sync-chat', {
        otherPersonId: this.chat.Message?.at(-1)?.senderId,
        chatId: this.chat.id,
      });
    }
  }

  async onSendMessage(message: Message) {
    console.log(this.chat);
    if (
      message.content.length + this.chat?.keysInfo[message.senderId!].end >
      this.chat?.keysInfo[message.senderId!].length
    ) {
      const keyData = await this.cryptoService.generateKey({
        SEED: this.chat?.id ?? '',
        SESSION: this.user.id,
        KEYS: message.content.length,
        BITS: 8,
        start: this.chat?.keysInfo[message.senderId!].end,
      });
      this.chat!.keysInfo[this.user.id] = {
        ...this.chat!.keysInfo[this.user.id],
        length:
          this.chat!.keysInfo[this.user.id].length + message.content.length,
        keys: this.chat!.keysInfo[this.user.id].keys.concat(keyData.keys),
        end: keyData.end,
      };
      message.content = message.content
        .split('')
        .map((x, i) => {
          return String(x.charCodeAt(0) ^ keyData.keys[i]);
        })
        .join(' ');
    } else {
      console.log(message);
      message.content = message.content
        .split('')
        .map((x, i) =>
          String(
            x.charCodeAt(0) ^
              this.chat?.keysInfo[message.senderId!].keys[
                i + this.chat?.keysInfo[message.senderId!].i
              ]
          )
        )
        .join(' ');
    }

    console.log('msg', message.content, message.content.length);
    this.webSocketService.emit('send-direct-message', {
      to: this.to.id,
      message: message.content,
    });
  }

  private addMessageToTodayThread(message: Message) {
    this.chat?.Message?.push(message);
    const today = format(new Date(), 'yyyy-MM-dd');

    if (!this.messagesByDate[today]) {
      this.messagesByDate[today] = [];
    }
    this.messagesByDate[today].push(message);
  }

  scrollToBottom() {
    const chatWrapper = this.chatWrapperRef.nativeElement as HTMLElement;
    chatWrapper.scrollTo({
      left: 0,
      top: chatWrapper.scrollHeight,
      behavior: 'instant',
    });
  }

  closeModal() {
    this.chatsService.callToReloadChats();
    this.controlService.close_modal();
  }

  callUser() {
    this.closeModal();

    if (!this.to) {
      return;
    }

    this.webSocketService.emit('create-call', { targetId: this.to.id });

    this.webSocketService.listen('created-call').subscribe((data) => {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          remoteUserNameToCaller: this.to.username,
        },
      };
      this.router.navigate(
        [constants.routes.meeting.default],
        navigationExtras
      );
    });

    this.webSocketService.listen('created-call-error').subscribe((data) => {
      console.log(data);
      this.controlService.show_toast(data as string);
    });
  }

  getChatName() {
    if (this.chat && !this.to) {
      return this.to;
    } else if (this.to && !this.chat) {
      return this.to.username;
    }
    return '';
  }

  getChatPhoneNumber() {
    if (this.chat && !this.to) {
      return this.chat?.name
        ? 'Grupo'
        : this.chat?.UserChat[0].User.phoneNumber;
    } else if (this.to && !this.chat) {
      return this.to.phoneNumber;
    }
    return '';
  }

  getAvatar() {
    return (
      this.to.avatarUrl ||
      'https://ionicframework.com/docs/img/demos/avatar.svg'
    );
  }

  getMessages() {
    if (this.chat && this.chat.Message) {
      return this.chat.Message;
    }
    return [];
  }

  updateUnreadMessagesStatus() {
    if (!this.chat) return;
    const unreadMessages = this.chat.Message?.filter(
      (m) => !m.readedAt && m.senderId !== this.user.id
    );
    unreadMessages?.forEach((message) => {
      this.webSocketService.emit('receive-message', { message });
    });
  }
}
