import { GetContactsRequestBody } from 'src/app/shared/models/request';
import { User } from 'src/app/shared/models/user.model';

export const argentinianMockNumber = '11 4403 4356';

const mockedUsersForChat: User[] = [
  {
    id: '1',
    avatarUrl: '',
    email: '',
    phoneNumber: '11',
    username: 'branodn mock, user app installd ningun message',
  },
  {
    id: '2',
    avatarUrl: '',
    email: '',
    phoneNumber: '22',
    username: 'lean mock, user app installd ningun message',
  },
];

export type ListUsersToChat = {
  filtered: User[];
  usersForInvite: GetContactsRequestBody[];
};

export const listUsersToChat: ListUsersToChat = {
  filtered: mockedUsersForChat,
  usersForInvite: [
    {
      phoneNumber: '33',
      display: 'for invite mario dock',
    },
  ],
};
