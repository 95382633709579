import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { IDictionary } from '../shared/models/storage.model';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private isWeb: boolean;
  private _storage: Storage | null = null;

  constructor(private platform: Platform, private storage: Storage) {
    this.init();
    this.isWeb = !this.platform.is('capacitor');
  }

  async init(): Promise<void> {
    if (!this._storage) {
      const storage = await this.storage.create();
      this._storage = storage;
    }
  }
  set(keyValuePair: IDictionary) {
    if (this.isWeb) {
      localStorage.setItem(keyValuePair.key, keyValuePair.value);
    } else {
      this.storage
        .set(keyValuePair.key, keyValuePair.value)
        .catch((error: any) => {
          throw error;
        });
    }
  }

  delete(listKey: string[]) {
    listKey.forEach((key: string) => {
      if (this.isWeb) {
        localStorage.removeItem(key);
      } else {
        this.storage.remove(key);
      }
    });
  }

  async get(key: string): Promise<string | null> {
    if (this.isWeb) {
      return localStorage.getItem(key);
    } else {
      return await this.storage.get(key);
    }
  }
}
