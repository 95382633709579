import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map } from 'rxjs';
import { endpoints } from '../constants/endpoints';
import { AppService } from './app.service';
import { UserCall } from '../shared/models/user-call.model';

@Injectable({
  providedIn: 'root'
})
export class CallService {

  constructor(
    private httpClient: HttpClient,
    private appService: AppService
  ){}
  getUserCallbyCallId(callId: string): Promise<UserCall>{
    return lastValueFrom(
      this.httpClient.get(endpoints.calls.call(callId)).pipe(
        map((r) => r as UserCall),
        catchError((error: any) =>
          this.appService.handleBackendError('getUserCallbyCallId', error)
        )
      )
    );
  }
}
