import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { constants } from 'src/app/constants/constants';
import { ControlService } from 'src/app/services/control.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { IModal } from 'src/app/shared/models/controls.model';
import { SocketReceiveCall } from 'src/app/shared/models/socket.model';
import { User } from 'src/app/shared/models/user.model';
import { DirectChatPage } from '../chat/direct-chat.page';
import { ChatsService } from 'src/app/services/chats.service';
import { ContactsService } from 'src/app/services/contacts.service';

@Component({
  selector: 'app-incoming-call',
  templateUrl: './incoming-call.page.html',
  styleUrls: ['./background.scss', './incoming-call.page.scss'],
})
export class IncomingCallPage implements OnInit {
  @Input() data!: SocketReceiveCall;
  ringtone!: HTMLAudioElement;

  constructor(
    private readonly webSocketService: WebSocketService,
    private readonly controlService: ControlService,
    private readonly router: Router,
    private chatsService: ChatsService,
    private contactsService: ContactsService
  ) {}

  ngOnInit() {
    this.ringtone = new Audio('assets/sounds/ringtone.mp3');
    this.ringtone.play();
    this.webSocketService
      .listen(`call-deleted/${this.data.callId}`)
      .subscribe(async (data: any) => {
        this.ringtone.pause();
        this.controlService.close_modal();
      });
  }

  async acceptCall() {
    this.ringtone.pause();
    const navigationExtras: NavigationExtras = {
      replaceUrl: true,
      queryParams: {
        remoteUserName:
          this.data.members[0].username || this.data.members[0].phoneNumber,
      },
    };

    this.controlService.close_modal();
    this.webSocketService.emit('accept-call', { callId: this.data.callId });
    this.router.navigate([constants.routes.meeting.default], navigationExtras);
  }

  endCallAndGoToChat(userCalling: User): void {
    this.rejectCall();
    this.goToChat(userCalling);
  }

 getNameOfCaller(member: any): string{
    return member.username || member.phoneNumber
  }

  endCallAndSendMessage(userCalling: User): void {
    this.rejectCall();
    this.goToChat(userCalling, 'No puedo atender ahora, te llamo luego.');
  }

  async goToChat(
    to: User,
    automaticPersonalizedMessage?: string
  ): Promise<void> {
    this.controlService.show_loader();
    const chat = await this.chatsService.getOrCreateChat(to.id);
    const modal: IModal = {
      component: DirectChatPage,
      componentProps: { to, chat, automaticPersonalizedMessage },
    };

    this.controlService.show_modal(modal).then(() => {
      this.router.navigateByUrl(constants.routes.home.default, {
        replaceUrl: true,
      });
    });
    this.controlService.hide_loader();
  }

  rejectCall() {
    this.webSocketService.emit('reject-call', { callId: this.data.callId });
    this.ringtone.pause();
    this.controlService.close_modal();
  }

  getAvatar() {
    return (
      this.data.members[0].avatarUrl ||
      'https://ionicframework.com/docs/img/demos/avatar.svg'
    );
  }
}
