import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map,
  Observable,
} from 'rxjs';
import { compareAsc, compareDesc, format, parseISO } from 'date-fns';
import { constants as c } from '../constants/constants';
import { AppService } from './app.service';
import { Chat, Message } from '../shared/models/chat.model';
import {
  ChatsSearchRequestBody,
  GetContactsRequestBody,
} from '../shared/models/request';
import { ListUsersToChat } from '../pages/home/shared/mocks/contacts.mock';
import { ContactsService } from './contacts.service';
import { CryptoService } from './crypto.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ChatsService {
  chats$: BehaviorSubject<Chat[]> = new BehaviorSubject<Chat[]>([]);

  callToReloadChats$: BehaviorSubject<undefined | any> = new BehaviorSubject<
    undefined | any
  >(undefined);

  callToReloadChats() {
    this.callToReloadChats$.next(true);
  }

  constructor(
    private appService: AppService,
    private contactsService: ContactsService,
    private httpClient: HttpClient,
    private cryptoService: CryptoService,
    private authService: AuthService
  ) {}

  async getChats(contacts: GetContactsRequestBody[]): Promise<Chat[]> {
    const url = c.endpoints.chats.default;
    return lastValueFrom(
      this.httpClient.post(url, { contacts }).pipe(
        map(async (r) => {
          const chatsPromise = (r as Chat[])
            .filter((x) => x.UserChat.length)
            .map((x) =>
              this.cryptoService.decryptChat(
                x,
                this.authService.authData.value?.user.id!,
                x.UserChat[0].User.id
              )
            );
          const chats = await Promise.all(chatsPromise);
          this.chats$.next(chats);
          return chats;
        }),
        catchError((error) =>
          this.appService.handleBackendError('getChats', error)
        )
      )
    );
  }

  async getChat(targetId: string, prev?: Chat): Promise<Chat> {
    const url = c.endpoints.chats.chat(targetId);
    return lastValueFrom(
      this.httpClient.get(url).pipe(
        map((r) => {
          return this.cryptoService.decryptChat(
            r as Chat,
            this.authService.authData.value?.user.id!,
            targetId,
            prev
          );
        }),
        catchError((error) =>
          this.appService.handleBackendError('getChats', error)
        )
      )
    );
  }

  async forceGetChat(targetId: string): Promise<Chat> {
    const url = c.endpoints.chats.force(targetId);
    return lastValueFrom(
      this.httpClient.get(url).pipe(
        map((r: any) => {
          r.UserChat = (r as Chat).UserChat.filter(
            (x) => x.User.id === targetId
          );
          r = this.cryptoService.decryptChat(
            r as Chat,
            this.authService.authData.value?.user.id!,
            targetId
          );
          return r;
        }),
        catchError((error) =>
          this.appService.handleBackendError('forceGetChat', error)
        )
      )
    );
  }

  getChatHistorial(chatId: string) {
    const url = c.endpoints.chats.historial + `/${chatId}`;
    return lastValueFrom(
      this.httpClient.get(url).pipe(
        map((r) => r as Message[]),
        catchError((error) =>
          this.appService.handleBackendError('getChats', error)
        )
      )
    );
  }

  parseMessagesByDate(messages: Message[]) {
    return messages.reduce((acc: any, message) => {
      const date = format(parseISO(message.createdAt.toString()), 'yyyy-MM-dd');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});
  }

  search(body: ChatsSearchRequestBody) {
    const url = c.endpoints.chats.search;
    return lastValueFrom(
      this.httpClient.post<ListUsersToChat>(url, body).pipe(
        map((r) => r),
        catchError((error) =>
          this.appService.handleBackendError('getChats', error)
        )
      )
    );
  }

  deleteChats(chatsId: string[]) {
    return lastValueFrom(
      this.httpClient
        .delete(c.endpoints.chats.default, {body: chatsId})
        .pipe(
          catchError((e) => this.appService.handleBackendError('deleteChats', e))
        )
    );
  }
  sortByLastMessage(chats: Chat[], senderId: string) {
    const sortedChats = chats.sort((a, b) => {
      const lastMessageA = a.Message?.[a.Message.length - 1]!;
      const lastMessageB = b.Message?.[b.Message.length - 1]!;

      const hasUnreadA =
        lastMessageA.readedAt === null && lastMessageA.senderId !== senderId;
      const hasUnreadB =
        lastMessageB.readedAt === null && lastMessageB.senderId !== senderId;

      if (hasUnreadA && !hasUnreadB) return -1;
      if (!hasUnreadA && hasUnreadB) return 1;

      return compareDesc(
        parseISO(lastMessageA.createdAt),
        parseISO(lastMessageB.createdAt)
      );
    });

    return sortedChats;
  }

  refeshChats() {
    const url = c.endpoints.chats.default;
    const contacts = this.contactsService.contactsListFlat$.getValue();
    return lastValueFrom(
      this.httpClient.post(url, { contacts }).pipe(
        map(async (r) => {
          const chats = (r as Chat[]).filter((x) => x.UserChat.length);
          const promises = chats.map((x) =>
            this.cryptoService.decryptChat(
              x,
              this.authService.authData.value?.user.id!,
              x.UserChat[0].User.id
            )
          );
          this.chats$.next(await Promise.all(promises));
        })
      )
    );
  }

  checkMissedCall() {
    const url = c.endpoints.calls.missedCalls;
    return lastValueFrom(this.httpClient.get(url).pipe(map((r) => r)));
  }

  async getOrCreateChat(userToId: string): Promise<Chat> {
    return lastValueFrom(
      this.httpClient.get(c.endpoints.chats.getOrCreateChat(userToId)).pipe(
        map(async (x) =>
          this.cryptoService.decryptChat(
            x as Chat,
            this.authService.authData.value?.user.id!,
            userToId
          )
        ),
        catchError((e) =>
          this.appService.handleBackendError('getOrCreateChat', e)
        )
      )
    );
  }
}
