export const routes = {
  auth: {
    default: '/auth',
  },
  home: {
    default: 'home',
  },
  meeting: {
    default: 'meeting',
  },
};
