import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import OneSignal, {
  NotificationClickResult,
  OSNotification,
} from 'onesignal-cordova-plugin';
import { environment } from 'src/environments/environment';
import { OSCallButtonId } from '../shared/enums/onesignal.enum';
import { IModal } from '../shared/models/controls.model';
import { DirectChatPage } from '../pages/chat/direct-chat.page';
import { ControlService } from './control.service';
import { ChatsService } from './chats.service';
import { AuthService } from './auth.service';
import { isMobile, sleep, UtilsService } from './utils.service';
import { WebSocketService } from './websocket.service';
import { Router } from '@angular/router';
import { constants } from '../constants/constants';
import { CallService } from './call.service';
import { ContactsService } from './contacts.service';
import { IncomingCallPage } from '../pages/incoming-call/incoming-call.page';

@Injectable({
  providedIn: 'root',
})
export class OnesignalNotificationService {
  constructor(
    private readonly controlService: ControlService,
    private readonly chatsService: ChatsService,
    private readonly authService: AuthService,
    private readonly utilsService: UtilsService,
    private readonly webSocketService: WebSocketService,
    private readonly router: Router,
    private platform: Platform,
    private callService: CallService,
    private readonly contactsService: ContactsService
  ) {}
  bindNotifications(): void {
    OneSignal.initialize(environment.oneSignalAppId);
    OneSignal.Notifications.requestPermission(true);

    OneSignal.Notifications.addEventListener('click', (res) => {
      const { notification, result } = res;
      this.utilsService.log('notif: ' + JSON.stringify(notification));
      this.handleNotificationClick(notification);
    });
  }

  async handleNotificationClick(notification: OSNotification) {
    try {
      const additionalData = notification.additionalData as any;
      if (!additionalData) return;
      if (additionalData.chatId) {
        await this.handleMessage(additionalData);
      } else if (additionalData.callId) {
        console.log({ callId: additionalData.callId });
        await this.handleCall(additionalData);
      }
    } catch (error) {
      this.controlService.show_toast(
        'Hubo un error abriendo el chat o la llamada'
      );
    }
  }

  async handleCall(additionalData: any) {
    let loadedContacts = this.contactsService.contactsListFlat$.getValue();
    if (!isMobile()) {
      loadedContacts = [
        { display: 'leandro', phoneNumber: '+54 11 3580 3252' },
        { display: 'brandon', phoneNumber: '+54 11 4403 4356' },
      ];
    }
    additionalData.members = [
      { name: additionalData.name, phoneNumber: additionalData.phoneNumber },
    ];
    const contact = loadedContacts.find(
      (c) => c.phoneNumber === additionalData.members[0].phoneNumber
    );

    additionalData.members[0].username = contact ? contact.display : '';

    const modal: IModal = {
      component: IncomingCallPage,
      componentProps: { data: additionalData },
    };
    if (!this.controlService.modalIsActive.getValue()) {
      console.log('callId', additionalData);
      const callData = await this.callService.getUserCallbyCallId(
        additionalData.callId
      );
      console.log('callData');
      const callIsActive = callData.isActive;
      if (callIsActive) {
        this.controlService.show_modal(modal);
      } else {
        this.router.navigate([constants.routes.home.default], {
          replaceUrl: true,
          queryParams: {
            isAnInactiveCall: true,
          },
        });
      }
    }
  }

  async handleMessage(additionalData: any) {
    await this.authService.loadAuthData();
    await this.chatsService.refeshChats();
    let chats = this.chatsService.chats$.getValue();

    const chat = chats.find((c) => additionalData.chatId === c.id);
    if (!chat) return;
    const modal: IModal = {
      component: DirectChatPage,
      componentProps: { to: chat.UserChat[0].User, chat },
    };
    this.controlService.show_modal(modal).then(() => {
      this.chatsService.callToReloadChats();
    });
  }

  async getUserOneSignalId(): Promise<any> {
    if (!this.platform.is('mobile')) return null;

    const OSSubscriptionId = OneSignal.User.pushSubscription.id;
    const APP_ID = environment.oneSignalAppId;

    const options = {
      method: 'GET',
      headers: { accept: 'application/json' },
    };

    const response = fetch(
      `https://onesignal.com/api/v1/apps/${APP_ID}/subscriptions/${OSSubscriptionId}/user/identity`,
      options
    )
      .then((response) => response.json())
      .then((response) => response.identity.onesignal_id)
      .catch((err) => console.error(err));

    return await response;
  }
}
