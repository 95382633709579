<div *ngFor="let date of Object.keys(parsedMessages)">
  <ion-item-divider sticky class="date">
    {{ parseDate(date) }}
  </ion-item-divider>

  <section class="chat-form">
    <div *ngFor="let m of parsedMessages[date]">
      <div class="msg-ctn own" *ngIf="m.senderId === userId">
        <div class="content text">
          <p>{{ m.content }}</p>
          <div class="flex justify-end gap-0.5 items-center">
            <ion-icon
              name="checkmark-done-outline"
              [ngStyle]="{ color: m.readedAt ? 'deepskyblue' : '#fafafa50' }"
            />
            <span>{{ getHour(m.createdAt) }}</span>
          </div>
        </div>
      </div>

      <div class="msg-ctn bot" *ngIf="m.senderId !== userId">
        <div class="content text">
          <p>{{ m.content }}</p>
          <span>{{ getHour(m.createdAt) }}</span>
        </div>
      </div>
    </div>
  </section>
</div>
