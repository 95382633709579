import { message } from './configs';
import { endpoints } from './endpoints';
import { regEx } from './regex';
import { routes } from './routes';
import { storage } from './storage';

export const constants = {
  regEx,
  endpoints,
  storage,
  routes,
  message,
};
